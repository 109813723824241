import { css } from '@emotion/react'
import React from 'react'
import PropTypes from 'prop-types'

import { CardElevation, TitleIcon } from '@findep/mf-landings-core'
import { TextField, Select, FormControl, InputLabel, Box } from "@material-ui/core"

import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';

import replaceAcents from '../../../helpers/replaceAcents'

class BeneficiariosCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: { firstName: '', lastName: '', mothersName: '', percentage: this.props.defaultPorcentaje, relationshipId: '' },
      error: false,
      unitError: { firstName: false, lastName: false, mothersName: false, percentage: false, relationshipId: false }
    }
  }

  componentDidMount() {
    this.watchData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultPorcentaje !== prevProps.defaultPorcentaje) {
      this.setState({ data: { ...this.state.data, percentage: this.props.defaultPorcentaje } })
    }
    if (prevState.data !== this.state.data) {
      this.watchData()
    }
    if (prevState.data.percentage !== this.state.data.percentage) {
      this.props.changePorcentage({ value: this.state.data.percentage, id: this.props.id ? this.props.id : 'Beneficiario-1' })
    }
  }

  watchData = () => {
    let dataArr = []
    for (var nowItem in this.state.data) {
      dataArr = [...dataArr, this.state.data[nowItem]]
    }
    let copyData = this.state.data
    if (!(dataArr.includes('')) && copyData.percentage > 0) {
      copyData = { ...copyData, percentage: Number(this.state.data.percentage) * 0.01, firstName: replaceAcents(copyData.firstName), lastName: replaceAcents(copyData.lastName), mothersName: replaceAcents(copyData.mothersName) }
      this.props.onChange({ value: copyData, id: this.props.id ? this.props.id : 'Beneficiario-1' })
      this.props.onError({ value: false, id: this.props.id ? this.props.id : 'Beneficiario-1' })
    } else {
      this.props.onError({ value: true, id: this.props.id ? this.props.id : 'Beneficiario-1' })
    }
  }

  root = css`
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    & div.is-danger .MuiInputBase-root{
      color: red !important;
    }    
  `
  mb1 = css`
    margin-bottom: 1rem !important;
    width: 100% !important;
  `

  mtId = css`
    ${this.props.id && 'margin-top: 1rem'}
    width: 100% !important;
  `

  closeIcon = css`
    color: rgba(0, 0, 0, 0.38);
  `

  handleInput = async (e) => {
    const item = e.target.name
    const value = e.target.value
    const element = e.target
    // let id = this.props.id ? this.props.id : 'Beneficiario-1'
    await this.setState({ unitError: { ...this.state.unitError, [item]: value === '' ? true : false } })

    const params = (item !== 'percentage' && item !== 'relationshipId') ? { maxLength: 30, pattern: /^[A-ZÑÁÉÍÓÚÜ ]*$/ } : item === 'percentage' && { maxLength: 4, pattern: /^[0-9]*$/ }
    const error = RegExp(params.pattern, 'i').test(value)

    if (item !== 'percentage' && item !== 'relationshipId') {
      if (error && value.length < params.maxLength) {
        await this.setState({ data: { ...this.state.data, [item]: value.toUpperCase() } })
        element.focus()
      }
    } else {
      if (item === 'percentage') {
        if (error && value.length < params.maxLength && Number(value) <= 100) {
          await this.setState({
            data: { ...this.state.data, [item]: value }
          })
          element.focus()
        }
      } else {
        await this.setState({
          data: { ...this.state.data, [item]: value }
        })
      }
    }
  }

  handleClose = () => {
    if (this.props.id) {
      this.props.onClose({ id: this.props.id })
    }
  }

  render() {
    return (
      <div css={this.mtId}>
        <CardElevation>
          {
            this.props.id && (
              <Box display="flex" justifyContent="flex-end">
                <CloseIcon id={this.props.id ? `seguro-vida-closeIcon-button-${this.props.id}` : 'seguro-vida-closeIcon-button-Beneficiario-1'} css={this.closeIcon} onClick={this.handleClose} />
              </Box>
            )
          }
          <Box css={this.root}>
            <Grid container justify="center">
              <Box css={this.mb1}>
                <TitleIcon icon={PersonIcon} title={this.props.id ? this.props.id.replace(/-/gi, ' ') : this.props.title} />
              </Box>
              <Grid item xs={12}>
                <TextField
                  id={this.props.id ? `seguro-vida-nombre-input-${this.props.id}` : 'seguro-vida-nombre-input-Beneficiario-1'}
                  css={this.mb1}
                  label="Nombre(s)"
                  disabled={this.props.disabled}
                  helperText={this.state.unitError.firstName ? 'El campo es obligatorio' : 'Ingresa tu nombre o nombres'}
                  variant="outlined"
                  name='firstName'
                  onChange={e => this.handleInput(e)}
                  value={this.state.data.firstName}
                  error={this.state.unitError.firstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={this.props.id ? `seguro-vida-lastname-input-${this.props.id}` : 'seguro-vida-lastname-input-Beneficiario-1'}
                  css={this.mb1}
                  label="Apellido Paterno"
                  disabled={this.props.disabled}
                  helperText={this.state.unitError.lastName ? 'El campo es obligatorio' : 'Ingresa tu apellido paterno'}
                  variant="outlined"
                  name='lastName'
                  onChange={e => this.handleInput(e)}
                  value={this.state.data.lastName}
                  error={this.state.unitError.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={this.props.id ? `seguro-vida-mothersName-input-${this.props.id}` : 'seguro-vida-mothersName-input-Beneficiario-1'}
                  css={this.mb1}
                  label="Apellido Materno"
                  disabled={this.props.disabled}
                  helperText={this.state.unitError.mothersName ? 'El campo es obligatorio' : 'Ingresa tu apellido materno'}
                  variant="outlined"
                  name='mothersName'
                  onChange={e => this.handleInput(e)}
                  value={this.state.data.mothersName}
                  error={this.state.unitError.mothersName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" css={this.mb1}>
                  <InputLabel htmlFor="outlined-age-native-simple">Relación</InputLabel>
                  <Select
                    id={this.props.id ? `seguro-vida-relationsshipId-select-${this.props.id}` : 'seguro-vida-relationsshipId-select-Beneficiario-1'}
                    color="primary"
                    disabled={this.props.disabled}
                    native
                    label='relationshipId'
                    name='relationshipId'
                    onChange={e => this.handleInput(e)}
                    value={this.state.data.relationshipId}
                    error={this.state.unitError.relationshipId}
                    fullWidth
                  >
                    <option aria-label="None" value="" />
                    {
                      this.props.catalogues &&
                      this.props.catalogues.map((item, index) => {
                        return <option key={index} value={item.id}>{item.displayName}</option>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={this.props.id ? `seguro-vida-porcentage-input-${this.props.id}` : 'seguro-vida-porcentage-input-Beneficiario-1'}
                  css={this.mb1}
                  label="Porcentaje"
                  disabled={this.props.disabled}
                  helperText={this.state.unitError.percentage ? 'El campo es obligatorio' : 'Porcentaje que deseas asignar a tu beneficiario'}
                  variant="outlined"
                  name='percentage'
                  onChange={e => this.handleInput(e)}
                  value={this.props.beneficiarioExtra ? 100 : this.state.data.percentage}
                  error={this.state.unitError.percentage}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </CardElevation>
      </div>
    );
  }
}


BeneficiariosCard.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  defaultPorcentaje: PropTypes.number,
  catalogues: PropTypes.array,
  changePorcentage: PropTypes.func,
};
BeneficiariosCard.defaultProps = {
  title: 'Beneficiario',
  disabled: false,
  defaultPorcentaje: 100,
  catalogues: [],
  onChange: () => { },
  onClose: () => { },
  onError: () => { },
  changePorcentage: () => { },
}


export default BeneficiariosCard;
